import "./src/styles/global.css"

// Require for Code Syntax Highlighting
require("prismjs/themes/prism-okaidia.css")

// Type Faces
require("typeface-montserrat")
require("typeface-comfortaa")
require("typeface-poppins")

